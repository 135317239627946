<template>
	<div class="outer-wrapper full-height">
		<div class="fixed-top outer-wrapper">
			<router-link 
				v-if="this.$route.query.trip_item_id && this.$route.query.trip_id"
				:to="{ name: 'Saved Tour Details', params: { trip_id: this.$route.query.trip_id, trip_item_id: this.$route.query.trip_item_id }}"
				>
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<router-link 
				v-else-if="this.$route.query.trip_id"
				:to="{ name: 'Trip', params: { trip_id: this.$route.query.trip_id }}"
				>
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<router-link 
				v-else
				:to="{ name: 'Home' }"
				>
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
			<div class="trip-type-holder">
				<span class="title">Tour Search</span>
			</div>
		</div>
		<div class="inner-wrapper tours search">
			<locationDropdown 
				v-model="toursSearch.searchValues.keyword"
				class="tour"

				placeholder="Country, city, activity or tour"
				locationType="tours"
			/>
			<button class="button" v-on:click="submit()" :class="{ disabled: !checkForm }">Continue</button>
		</div>
	</div>
</template>

<script>
	import { mapState, mapActions, mapGetters } from 'vuex'
	import locationDropdown from '@/components/locationDropdown.vue';
	import router from '@/router';

	export default {
		name: 'toursSearchKeyword',
		data() {
			return {
				errors: new Map(),
			}
		},
		components: {
			locationDropdown,
		},
		computed: {
			...mapState({
				toursSearch: state => state.toursSearch,
			}),
			checkForm() {
				this.errors = new Map();
				let keywordErrors = [];
				if(this.toursSearch.searchValues.keyword == null || this.toursSearch.searchValues.keyword == "") {
					keywordErrors.push('You must enter a keyword search');
				}
				if(keywordErrors.length > 0) {
					this.errors.set('keyword', keywordErrors);
				}
				if (!this.errors.size > 0) {
					return true;
				}
				return false;
				e.preventDefault();
			},
		},
		methods: {
			...mapActions({
				mapQueryParams: 'toursSearch/mapQueryParams',
			}),
			submit() {
				if(this.checkForm) {
					router.push({
						name: 'Tours Dates',
					})
				}
			},
		},
		created () {
			this.toursSearch.shouldSearch = true;

			this.mapQueryParams();
		},
	}
</script>

<style scoped>
	.button {
		display: block;
		margin: 0 auto;
	}
	.trip-type-holder {
		position: relative;
		text-align: center;
	}
	.title {
		width: 125px;
		display: inline-block;
		color: #118AB2;
		font-weight: bold;
		font-size: 20px;
	}
</style>